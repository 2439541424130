import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import userApi from '../api/userApi';
import boardApi from '../api/boardApi';
// import companyApi from '../api/companyApi';
import loadApi from '../api/loadApi';
import {
  Box,
  Divider,
  MenuItem,
  Select,
  Typography,
  AppBar,
  Toolbar,
  Container,
  Grid,
} from '@mui/material';
import UserMenu from '../components/common/UserMenu';
import '../css/App.css';

function UserActivity() {
  const [title, setTitle] = useState("User Activity");
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [boards, setBoards] = useState([]);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [rowCount, setRowCount] = useState(0);
  const [sortModel, setSortModel] = useState([
    { field: "updated_at", sort: "desc" },
  ]);
  const [filterModel, setFilterModel] = useState({});

  const user = useSelector((state) => state.user.value);
  const navigate = useNavigate();

  // Redirects to home page if you're not worthy
  useEffect(() => {
    if (!user?.groups.includes("LM_Admin") && !user?.groups.includes("LM_Staff") ) {
      navigate("/");
    }
  }, [user, navigate]);

  // filter the grid
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedBoard, setSelectedBoard] = useState(
    "652ee32a45b4ca80dbbbdfd3",
  );
  // const [selectedCompany, setSelectedCompany] = useState('');
  // const [companies, setCompanies] = useState([]);

  const handleBoardChange = (event) => {
    setSelectedBoard(event.target.value);
  };

  // const handleCompanyChange = (event) => {
  //   setSelectedCompany(event.target.value);
  // };

  const handleUserChange = (event) => {
    setSelectedUser(event.target.value);
  };

  function renderSelects() {
    return (
      <Grid
        container
        spacing={2}
        sx={{ minWidth: 120, alignItems: "center", my: 1 }}
      >
        {boards.length > 0 && (
          <Grid item>
            <Select
              value={selectedBoard || ""}
              onChange={handleBoardChange}
              displayEmpty
              size="small"
            >
              <MenuItem value="">
                <Typography color={(theme) => theme.palette.grey[500]}>
                  Select a customer
                </Typography>
              </MenuItem>
              {boards
                .filter((board) => board.title !== "Tasks")
                .map((board) => (
                  <MenuItem key={board._id} value={board._id}>
                    {board.title}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
        )}

        {/* {companies.length > 0 && (
          <Grid item>
            <Select
              value={selectedCompany || ''}
              onChange={handleCompanyChange}
              size="small"
              displayEmpty
            >
              <MenuItem value="">
                <em>Select a Company</em>
              </MenuItem>
              {companies.map((company) => (
                <MenuItem key={company.id} value={company.id}>
                  {company.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        )} */}

        {users.length > 0 && (
          <Grid item>
            <Select
              value={selectedUser}
              onChange={handleUserChange}
              displayEmpty
              size="small"
            >
              <MenuItem value="">
                <Typography color={(theme) => theme.palette.grey[500]}>
                  Select a user
                </Typography>
              </MenuItem>
              {users.map((user) => (
                <MenuItem key={user.id} value={user.name}>
                  {user.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        )}
      </Grid>
    );
  }

  // fetch Users
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const res = await userApi.getAll();
        if (res) {
          const formattedUsers = res.map((user) => ({
            id: user._id,
            name: `${user.firstname} ${user.lastname}`,
          }));
          // sorting them alphabetically
          formattedUsers.sort((a, b) => a.name.localeCompare(b.name));
          setUsers(formattedUsers);
        }
      } catch (err) {
        console.error(err);
      }
    };
    fetchUsers();
  }, []);

  // fetch Boards
  useEffect(() => {
    const fetchBoards = async () => {
      try {
        setLoading(true);
        const res = await boardApi.getAll();
        if (res) {
          // dispatch(setBoards(res));
          setBoards(res);
        }
      } catch (err) {
        console.error("Error fetching boards:", err);
      }
    };
    fetchBoards();
    setLoading(false);
  }, []);

  // //Companies TODO: tie filter to selected board & user
  // useEffect(() => {
  //   const fetchCompanies = async () => {
  //     try {
  //       const res = await companyApi.getAll();
  //       // console.log('Companies API Response:', res); // Debugggggg
  //       if (res) {
  //         const formattedCompanies = res.map((company) => ({
  //           id: company._id,
  //           name: company.name,
  //         }));
  //         setCompanies(formattedCompanies);
  //       }
  //     } catch (err) {
  //       console.error('Error fetching companies:', err);
  //     }
  //   };

  //   fetchCompanies();
  // }, [dispatch]);

  // fetch Histories
  const fetchHistories = useCallback(async () => {
    setLoading(true);
    const sortField = sortModel[0]?.field || "defaultSortField";
    const sortOrder = sortModel[0]?.sort || "asc";
    const sort = `${sortOrder === "asc" ? "" : "-"}${sortField}`;

    const loadPromises = boards
      .filter((board) => board && board._id === selectedBoard)
      .map((board) =>
        loadApi
          .getAll(board._id, 1, 1200, sort, filterModel)
          .then((loads) => ({ boardId: board._id, loads })),
      );

    const results = await Promise.all(loadPromises);
    const allLoads = results.flatMap((result) =>
      result.loads.map((load) => ({ ...load, boardId: result.boardId })),
    );
    // console.log('All Loads:', allLoads); // Debug

    const loadHistories = allLoads.flatMap((load) =>
      load.histories.map((history) => ({
        ...history,
        loadId: load._id,
        boardId: load.boardId,
      })),
    );
    // console.log('Load Histories:', loadHistories); // Debug

    let histories = loadHistories;
    if (selectedUser) {
      histories = histories.filter((history) => history.user === selectedUser);
    }

    // adding timeout to simulate server response delay
    setTimeout(() => {
      setRows(histories);
      setRowCount(histories.length);
      setLoading(false);
    }, 1000);
  }, [boards, filterModel, selectedBoard, selectedUser, sortModel]);

  useEffect(() => {
    if (boards.length > 0 && selectedBoard) {
      fetchHistories();
    }
    return () => {};
  }, [boards, selectedBoard, fetchHistories]);

  // History Details
  function DetailPanelContent({ row }) {
    return (
      <div style={{ width: "100%" }}>
        <Box p={3} sx={{ backgroundColor: "var(--backgroundSecondary)" }}>
          <Typography variant="h6" gutterBottom component="div">
            History Details
          </Typography>
          <Typography variant="body2" gutterBottom component="div">
            <strong>User:</strong> {row.user}
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography variant="body2" gutterBottom component="div">
              <strong>Description:</strong>
              {row.description.map((line, index) => (
                <div key={index}>
                  {line}
                  <Divider style={{ margin: "5px 0" }} />
                </div>
              ))}
            </Typography>
          </Box>
        </Box>
      </div>
    );
  }
  const loadsColumns = useMemo(
    () => [
      { field: "_id", hide: true },
      { field: "user", headerName: "User", width: 200 },
      {
        field: "description",
        headerName: "Description",
        minWidth: 400,
        flex: 1,
      },
      {
        field: "timestamp",
        headerName: "Timestamp",
        width: 200,
        type: "dateTime",
        valueFormatter: ({ value }) => new Date(value).toLocaleString(),
      },
    ],
    [],
  );
  const getDetailPanelContent = useCallback(
    ({ row }) => <DetailPanelContent row={row} />,
    [],
  );
  const getDetailPanelHeight = useCallback(() => "auto", []);

  // render the grid
  function renderUserActivityGrid({
    loadsColumns,
    getDetailPanelContent,
    getDetailPanelHeight,
  }) {
    return (
      <DataGridPro
        rows={rows}
        columns={loadsColumns}
        pagination
        page={page}
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        onSortModelChange={(model) => setSortModel(model)}
        onFilterModelChange={(model) => setFilterModel(model)}
        pageSize={pageSize}
        rowCount={rowCount}
        autoHeight
        loading={loading}
        density="compact"
        getRowId={(row) => `${row._id}-${row.timestamp}`}
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={getDetailPanelHeight}
        slots={{
          toolbar: GridToolbar,
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 25,
              page: 0,
              rowCount: 0,
            },
          },
          sorting: {
            sortModel: [{ field: "timestamp", sort: "desc" }],
          },
          columns: {
            columnVisibilityModel: {
              _id: false,
            },
          },
        }}
        state={{
          pagination: {
            paginationModel: {
              pageSize: pageSize,
              page: page,
            },
          },
        }}
        onStateChange={(newState) => {
          setPage(newState.pagination.paginationModel.page);
          setPageSize(newState.pagination.paginationModel.pageSize);
        }}
        sx={{
          backgroundColor: "white",
          border: "none",
          padding: { xs: 1, md: 2 },
        }}
      />
    );
  }

  // console.log('Selected Board:', selectedBoard);
  // console.log('Selected Company:', selectedCompany);
  // console.log('Selected User:', selectedUser);

  // render the page
  return (
    <Box width={"100vw"}>
      <AppBar
        position="static"
        color="inherit"
        className="appBar"
        sx={{ maxWidth: "100vw" }}
      >
        <Toolbar>
          <Typography
            component="div"
            sx={{
              fontFamily: "var(--system-ui)",
              fontSize: { xs: "1rem", md: "1.25rem" },
              flexGrow: "1",
              mr: "1rem",
              fontWeight: "bolder",
              whiteSpace: "nowrap",
            }}
          >
            <Typography variant="subtitle2">
              Load Manager Back Office{" "}
            </Typography>
            {title}
          </Typography>
          <Box className="userMenu">
            <UserMenu />
          </Box>
        </Toolbar>
      </AppBar>
      <Container
        maxWidth="false"
        sx={{
          padding: {
            xs: 1,
            md: 2,
            backgroundColor: "var(--backgroundSecondary)",
          },
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {renderSelects()}
          </Grid>
        </Grid>
        {renderUserActivityGrid({
          loadsColumns,
          getDetailPanelContent,
          getDetailPanelHeight,
        })}
      </Container>
    </Box>
  );
}

export default UserActivity;
